/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable @shopify/jsx-no-complex-expressions */
import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Bmu from './bmu.js';
import { EssRackContext } from './essRackContext.js';
import {
  bmuDataUpdate,
  inverterDataUpdate,
  rackDataUpdate
} from '../helpers/essHandlers.js';
import { useInterval } from '../helpers/useInterval.js';

const BmuControl = (function BmuControl({
  bmu_id,
  row,
  column_name,
  readings,
  selectedReadingName,
  setSelectedReadingName,
  selectedReadingIndex,
  setSelectedReadingIndex,
  bmuControlHandler,
  bmuControl,
  dataSource
}) {
  const [bmus, setBmus] = useState([]);
  const bmusRef = useRef(bmus);
  const [inverters, setInverters] = useState([]);
  const invertersRef = useRef(inverters);
  const [currentRacksSDSs, setCurrentRacksSDSs] = useState({});
  const [rackState, setRackState] = useState('operational');
  const [fanState, setFanState] = useState('on');
  const [hasFanRelay, setHasFanRelay] = useState(false);
  const { selectedRack, essConfig } = useContext(EssRackContext);
  const [selectedBmu, setSelectedBmu] = useState({});
  const [dbSoc, setDbSoc] = useState('');
  const [dbRate, setDbRate] = useState('');
  const [soc, setSoc] = useState('0');
  const [rate, setRate] = useState('0');
  const [state, setState] = useState('');

  const bmuControlDataHandler = (data) => {
    bmuDataUpdate(data, bmusRef, setBmus);
  };

  const inverterControlDataHandler = (data) => {
    inverterDataUpdate(data, invertersRef, setInverters);
  };

  const rackControlDataHandler = (data) => {
    rackDataUpdate(data, setFanState, setHasFanRelay, setRackState);
  };

  const handleButtonClick = () => {
    let tempSoc = Number(soc);
    let tempRate = Number(rate);

    if (state == 'Maintenance' || state == 'Unrestricted') {
      if (tempSoc >= 0 && tempSoc <= 100 && tempRate >=0) {
        dataSource.updateBmuState(state, soc, rate, bmu_id);
      } else {
        alert("Please enter a SOC between 0 and 100 (inclusive). Make sure the ramp rate is greater than or equal to 0.")
      }
    }
    
  }

  const bmuTargetInfoHandler = (data) => {
    if ('target_soc' in data) {
      let target_soc = data['target_soc']
      if (target_soc != dbSoc) {
        setDbSoc(target_soc)
      } 
    }
    if ('rate' in data) {
      let rate = data['rate']
      if (rate != dbRate) {
        setDbRate(rate)
      } 
    }
  }

  useEffect(() => {
    setSoc(dbSoc)
  }, [dbSoc])

  useEffect(() => {
    setRate(dbRate)
  }, [dbRate])

  useEffect(() => {
    dataSource.getBmuTargetInfo(bmu_id, bmuTargetInfoHandler)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      dataSource.getBmuTargetInfo(bmu_id, bmuTargetInfoHandler)
    }, 5000);
  
    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    dataSource.registerEssReadingsHandler(
      essConfig,
      selectedRack,
      bmuControlDataHandler,
      inverterControlDataHandler,
      rackControlDataHandler
    );

    return function cleanup() {
      dataSource.close?.();
    };
  }, [essConfig.ulid, essConfig.essId, selectedRack, currentRacksSDSs]);
  
  return (
    <div style={{ fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif' }}>
      <Button
          style={{ margin: '20px', float: 'right', zIndex: 5 }}
          onClick={() => bmuControlHandler({})}
        >
        ESS Rack
      </Button>
      <div style={{ width: '350px' }}>
        {bmus.map((bmu) => {
          // Display each BMU on the rack.
          if (
            bmu.bmu_id &&
            bmu.bmu_id==bmu_id &&
            bmu.rack_slot_number &&
            bmu.column_name &&
            essConfig.numRows
          ) {
            return (
              <Bmu
                bmu_id={bmu_id}
                row={row}
                column_name={column_name}
                readings={bmu.readings ? bmu.readings : ''}
                selectedReadingName={selectedReadingName}
                setSelectedReadingName={setSelectedReadingName}
                selectedReadingIndex={selectedReadingIndex}
                setSelectedReadingIndex={setSelectedReadingIndex}
                bmuControlHandler={bmuControlHandler}
                bmuControl={bmuControl}
                key={bmu_id}
              />
            );
          }
        })}
        <label style={{ position: "absolute", top: '200px', fontSize: '18px' }}>Target SOC(%):</label>
        <input type="number" min="0" max="100" inputMode='numeric' style={{ position: 'absolute', top: '200px', left: '330px', width: '50px', color: 'black', fontSize: '18px' }} value={soc} onChange={(e) => setSoc(e.target.value)}/>
        <label style={{ position: 'absolute', top: '250px', fontSize: '18px' }}>Ramp Rate(kW):</label>
        <input type="number" min="0" step="0.01" inputMode='numeric' style={{ position: 'absolute', top: '250px', left: '315px', width: '65px', color: 'black', fontSize: '18px' }} value={rate} onChange={(e) => setRate(e.target.value)}/>
        <select onChange={(e) => setState(e.target.value)} style={{ position: 'absolute', top: '300px', width: '205px', color: 'black', fontSize: '18px'}}>
          <option selected disabled>Select a mode:</option>
          <option>Maintenance</option>
          <option>Unrestricted</option>
        </select>
        <Button style={{ position: 'absolute', top: '350px', marginLeft: '62.5px', fontSize: '18px' }} onClick={handleButtonClick}>Submit</Button>
      </div>
    </div>
  );
});

BmuControl.propTypes = {
  bmu_id: PropTypes.string,
  row: PropTypes.string,
  column_name: PropTypes.string,
  readings: PropTypes.object,
  selectedReadingName: PropTypes.string,
  setSelectedReadingName: PropTypes.func,
  selectedReadingIndex: PropTypes.number,
  setSelectedReadingIndex: PropTypes.func,
  bmuControlHandler: PropTypes.func,
  bmuControl: PropTypes.bool,
  dataSource: PropTypes.any
};

export default BmuControl;
