/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable @shopify/jsx-no-complex-expressions */
import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import { EssRackContext } from './essRackContext.js';
import BmuMetalRack from './bmuMetalRack.js';
import EssRackColumnNames from './essRackColumnNames.js';
import Bmu from './bmu.js';
import Inverter from './inverter.js';
import SdsRow from './sdsRow.js';
import Fan from './fan.js';
import {
  bmuDataUpdate,
  inverterDataUpdate,
  rackDataUpdate
} from '../helpers/essHandlers.js';
import BmuControl from './bmuControl.js';

const EssRack = React.memo(function EssRack(props) {
  /* Props: dataSource={props.dataSource}
            viewMode={(props.viewMode ? props.viewMode : "Monitor")} */
  // BMUs object is received from datasource with topic (e.g. "bmu2#") as key and dict as value,
  // though we change the bmus to be a list of bmu dictionaries.
  // The Inverters object is also changed to a list of inverter dictionaries.
  const [bmus, setBmus] = useState([]);
  const bmusRef = useRef(bmus);
  const [inverters, setInverters] = useState([]);
  const invertersRef = useRef(inverters);
  const [selectedReadingName, setSelectedReadingName] = useState('current');
  const [selectedReadingIndex, setSelectedReadingIndex] = useState(0);
  const [selectedInverterReadingName, setSelectedInverterReadingName] =
    useState('DcVsolar');
  const [selectedInverterReadingIndex, setSelectedInverterReadingIndex] =
    useState(0);
  const [currentRacksSDSs, setCurrentRacksSDSs] = useState({});
  const [rackState, setRackState] = useState('operational');
  const [fanState, setFanState] = useState('on');
  const [hasFanRelay, setHasFanRelay] = useState(false);
  const { selectedRack, essConfig } = useContext(EssRackContext);
  const [bmuControl, setBmuControl] = useState(0);
  const [selectedBmu, setSelectedBmu] = useState({});

  const bmuDataHandler = (data) => {
    bmuDataUpdate(data, bmusRef, setBmus);
  };

  const inverterDataHandler = (data) => {
    inverterDataUpdate(data, invertersRef, setInverters);
  };

  const rackDataHandler = (data) => {
    rackDataUpdate(data, setFanState, setHasFanRelay, setRackState);
  };

  const bmuControlHandler = (bmu) => {
    if (bmu && Object.keys(bmu).length > 0) {
      setBmuControl(bmu.bmu_id);
      props.setBmuControl(bmu.bmu_id);
      setSelectedBmu({
        bmu_id: bmu.bmu_id,
        row: bmu.row,
        column_name: bmu.column_name,
        readings: bmu.readings,
        selectedReadingName: selectedReadingName,
        setSelectedReadingName: setSelectedReadingName,
        selectedReadingIndex: selectedReadingIndex,
        setSelectedReadingIndex: setSelectedReadingIndex,
        bmuControlHandler: bmuControlHandler,
        key: bmu.bmu_id
      })
    } else {
      setBmuControl(0);
      props.setBmuControl(0);
      setSelectedBmu({});
    }
  }

  // Any time the ESS ID or selected ESS rack changes, register data handler for that ess/rack.
  useEffect(() => {
    props.dataSource.registerEssReadingsHandler(
      essConfig,
      selectedRack,
      bmuDataHandler,
      inverterDataHandler,
      rackDataHandler
    );

    return function cleanup() {
      props.dataSource.close?.();
    };
  }, [essConfig.ulid, essConfig.essId, selectedRack, currentRacksSDSs]);

  useEffect(() => {
    setInverters([]);
    setBmus([]);
  }, [selectedRack]);

  // Make sure we close the data source when the window is closed
  useEffect(() => {
    const closeDataSource = (e) => {
      e.preventDefault();
      props.dataSource.close();
    };

    window.addEventListener('beforeunload', closeDataSource);
    return () => {
      window.removeEventListener('beforeunload', closeDataSource);
    };
  }, [props.dataSource]);

  // Render wants bmu list where key is topic
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {bmuControl > 0 && Object.keys(selectedBmu).length > 0 ? 
        <BmuControl 
          bmu_id={selectedBmu.bmu_id}
          row={selectedBmu.row}
          column_name={selectedBmu.column_name}
          readings={selectedBmu.readings ? selectedBmu.readings : ''}
          selectedReadingName={selectedReadingName}
          setSelectedReadingName={setSelectedReadingName}
          selectedReadingIndex={selectedReadingIndex}
          setSelectedReadingIndex={setSelectedReadingIndex}
          bmuControlHandler={bmuControlHandler}
          bmuControl={true}
          dataSource={props.dataSource}
          key={selectedBmu.bmu_id}
        /> :
        <div
          className='ess-rack'
          style={{ width: essConfig.numCols > 1 ? '560px' : '294px' }}
        >
          {essConfig.numCols > 1 && <EssRackColumnNames />}
          <BmuMetalRack />

          {
            // Only display the rack fan if the rack has a fan relay.
            hasFanRelay && <Fan fanState={fanState} />
          }

          {bmus.map((bmu) => {
            // Display each BMU on the rack.
            if (
              bmu.bmu_id &&
              bmu.rack_slot_number &&
              bmu.column_name &&
              essConfig.numRows
            ) {
              return (
                <Bmu
                  bmu_id={bmu.bmu_id}
                  row={bmu.rack_slot_number}
                  column_name={bmu.column_name}
                  readings={bmu.readings ? bmu.readings : ''}
                  selectedReadingName={selectedReadingName}
                  setSelectedReadingName={setSelectedReadingName}
                  selectedReadingIndex={selectedReadingIndex}
                  setSelectedReadingIndex={setSelectedReadingIndex}
                  bmuControlHandler={bmuControlHandler}
                  bmuControl={false}
                  key={bmu.bmu_id}
                />
              );
            }
          })}

          {inverters.map((inverter) => {
            // Display each inverter on the rack.
            if (inverter.info && inverter.info.serial_number) {
              return (
                <Inverter
                  key={inverter.info.serial_number}
                  info={inverter.info ? inverter.info : ''}
                  status={inverter.status ? inverter.status : ''}
                  selectedInverterReadingName={selectedInverterReadingName}
                  setSelectedInverterReadingName={setSelectedInverterReadingName}
                  selectedInverterReadingIndex={selectedInverterReadingIndex}
                  setSelectedInverterReadingIndex={
                    setSelectedInverterReadingIndex
                  }
                />
              );
            }
          })}

          {
            // Only display the SDS section if the ESS is configurable.
            essConfig.configurable && (
              <SdsRow
                currentRacksSDSs={currentRacksSDSs}
                setCurrentRacksSDSs={setCurrentRacksSDSs}
                viewMode={props.viewMode}
              />
            )
          }
        </div>
      }
    </div>
  );
});

EssRack.propTypes = {
  rackState: PropTypes.object,
  dataSource: PropTypes.object,
  viewMode: PropTypes.string
};

export default EssRack;
