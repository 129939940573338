/* eslint-disable no-unused-vars */
/* eslint-disable @shopify/jsx-no-complex-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { renderReading } from '../readings.js';
import { isOnline } from '../helpers/isOnline.js';
import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';

export const Generator = ({
  ulid,
  name,
  xaxis,
  yaxis,
  titlePosition,
  viewProps,
  overrideName,
  timezone,
  isTabletOrMobile
}) => {
  const [readings, setReadings] = useState({});
  const [generatorName, setGeneratorName] = useState(overrideName || name);
  const [meterReading, setMeterReading] = useState('');
  const [color, setColor] = useState('white');
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  const [selectedReading, setSelectedReading] = useState(() => {
    if (localStorage.getItem('selectedReading'.concat(ulid)) != null) {
      return localStorage.getItem('selectedReading'.concat(ulid));
    }
    return 'kw_reading';
  });
  const [online, setOnline] = useState(false);

  useEffect(() => {
    subscribe(meterReadingsEventName, (e) =>
      setReadings(e.detail?.[ulid] || {})
    );
    return () => unsubscribe(meterReadingsEventName);
  }, [ulid]);

  useEffect(() => {
    localStorage.setItem('selectedReading'.concat(ulid), selectedReading);
    let _online = false;
    if (Object.keys(readings).length !== 0) {
      _online =
        'read_at' in readings
          ? isOnline(parseInt(readings.read_at) * 1000)
          : true;
      if (!overrideName && 'name' in readings) {
        setGeneratorName(readings?.name || generatorName);
      } else if (overrideName) {
        setGeneratorName(overrideName);
      }
      if (selectedReading in readings) {
        setMeterReading(
          renderReading(
            selectedReading,
            setSelectedReading,
            readings,
            _online,
            timezone,
            isTabletOrMobile
          )
        );
      }
    }
    setOnline(true);
  }, [readings, selectedReading]);

  function offlineClass(className) {
    return !online ? className : '';
  }

  const gray = '#474747';

  useEffect(() => {
    if (selectedUlids.includes(ulid)) {
      setColor('yellow');
    } else if (!online) {
      setColor(gray);
    } else {
      setColor('white');
    }
  }, [online, selectedUlids]);

  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  return (
    <div className='apparent-module generator' id={ulid} style={customStyle}>
      <div className='generator-container'>
        {(titlePosition === undefined || titlePosition === 'top') && (
          <div className={`generator-name ${offlineClass('offline')}`}>
            {generatorName}
          </div>
        )}
        {titlePosition === 'left' && (
          <div
            className={`generator-name ${offlineClass('offline')}`}
            style={{
              position: 'fixed',
              marginLeft: '-50px',
              marginTop: '20px'
            }}
          >
            {generatorName}
          </div>
        )}
        {titlePosition === 'right' && (
          <div
            className={`generator-name ${offlineClass('offline')}`}
            style={{
              position: 'fixed',
              marginLeft: '135px',
              marginTop: '20px'
            }}
          >
            {generatorName}
          </div>
        )}
        <div className='generator-exhaust' />
        <div className='generator-exhaust-base' />
        <div className={`generator-block ${offlineClass('offline-border')}`}>
          <div className={`generator-readings ${offlineClass('offline')}`}>
            {meterReading}
          </div>
          <div className='generator-lines'>
            <div className='generator-line' />
            <div className='generator-line' />
            <div className='generator-line' />
          </div>
          <div className='generator-lines'>
            <div className='generator-line' />
            <div className='generator-line' />
            <div className='generator-line' />
          </div>
          <div className='generator-lines'>
            <div className='generator-line' />
            <div className='generator-line' />
            <div className='generator-line' />
          </div>
        </div>
        <div className='generator-stand' />
        <div className='generator-wheel' />
        {titlePosition === 'bottom' && (
          <div className='generator-name' style={{ color: `${color}` }}>
            {generatorName}
          </div>
        )}
      </div>
    </div>
  );
};

Generator.propTypes = {
  name: PropTypes.string,
  ulid: PropTypes.number,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  viewProps: PropTypes.object,
  overrideName: PropTypes.string,
  timezone: PropTypes.string,
  titlePosition: PropTypes.string,
  isTabletOrMobile: PropTypes.bool
};
