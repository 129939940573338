/* eslint-disable @shopify/jsx-no-complex-expressions */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { isReadingZero } from '../helpers/isReadingZero.js';

export const BmuReadingsSelector = ({
  readings,
  onlineStatus,
  selectedReadingName,
  setSelectedReadingName,
  selectedReadingIndex,
  setSelectedReadingIndex,
  column_name
}) => {
  const readingsSelectField = useRef(0);

  const handleReadingsSelector = (selectValue) => {
    const newSelectedReadingName =
      selectValue.options[selectValue.selectedIndex].attributes.readingname
        .nodeValue;
    setSelectedReadingName(newSelectedReadingName);
    setSelectedReadingIndex(selectValue.selectedIndex);
  };

  useEffect(() => {
    handleReadingsSelector(readingsSelectField.current);
    readingsSelectField.current.selectedIndex = selectedReadingIndex;
  }, [handleReadingsSelector, selectedReadingIndex]);

  useEffect(() => {
    readingsSelectField.current.selectedIndex = selectedReadingIndex;
    handleReadingsSelector(readingsSelectField.current);
  }, [
    handleReadingsSelector,
    selectedReadingIndex,
    readingsSelectField.current.value
  ]);

  // This function is called after each change in value of the readings selector.
  const handleReadingsSelectChange = (e) => handleReadingsSelector(e.target);
  const handleClick = (e) => e.stopPropagation();

  const readingUnit = {
    current: ' A',
    voltage: ' V',
    soc: '% SOC',
    soh: '% SOH',
    remaining_capacity: '% Rem Cap',
    full_charged_capacity: '% Full Chrgd Cap',
    design_capacity: '% Design Cap',
    cycle_count: ' Cycle Count',
    mosfet_temperature: ' \u00B0C Mosfet Temp',
    environment_temperature: ' \u00B0C Env Temp',
    state: '',
    state_reason: '',
    serial_number: ' SN',
    timestamp: ''
  };

  // only display 3 decimal places for certain values
  const displayValue = (value, reading) => {
    if (value !== '' && value !== null && value !== undefined) {
      if (
        reading === 'current' ||
        reading === 'voltage' ||
        reading === 'mosfet_temperature' ||
        reading === 'environment_temperature' ||
        reading === 'remaining_capacity' ||
        reading === 'full_charged_capacity'
      ) {
        value = parseFloat(value);

        // Show positive sign before the current and voltage.
        // There is already a negative sign if the value is negative.
        if (reading === 'current' || reading === 'voltage') {
          let sign = '';
          if (value > 0) {
            sign = '+';
          }
          return sign + value.toFixed(3).toString();
        } else {
          // No added sign needed for other readings.
          return value.toFixed(3);
        }
      } else {
        // Do not need to fix number of digits after the decimal point
        // for the rest of the readings, just return.
        return value;
      }
    } else {
      // value is "", null, or undefined.
      return '--';
    }
  };

  // These useStates would keep track of the blink class for bmu readings if they change value.
  // This functionality of blinking the value when they change so that the user can tell that
  // the reading has changed is not currently working.
  // Uncomment this if we want to use it/if we get it working.
  /* const [blinkCurrent, setBlinkCurrent] = useState("")
  const [blinkVoltage, setBlinkVoltage] = useState("")
  const [blinkReading, setBlinkReading] = useState("") */

  /* Blink readings on change of value not working yet. Uncomment if working */
  /* useEffect(() => {
    setBlinkCurrent("")
    setBlinkCurrent("value-blink")
  }, [readings.current])
  useEffect(() => {
    setBlinkVoltage("")
    setBlinkVoltage("value-blink")
  }, [readings.voltage])
  useEffect(() => {
    if (selectedReadingName !== "current" && selectedReadingName !== "voltage")
    setBlinkReading("")
    setBlinkReading("value-blink")
  }, [readings[selectedReadingName]]) */

  // Sort the BMU readings into a prefered display order.
  const sortReadingsForDisplay = (readings) => {
    // This is the order we want the inverter readings to display in.
    // Change this order if we want the readings to display in a different order.
    const params_arr = [
      'current',
      'voltage',
      'soc',
      'soh',
      'remaining_capacity',
      'full_charged_capacity',
      'design_capacity',
      'cycle_count',
      'cell_voltages',
      'cell_temperatures',
      'mosfet_temperature',
      'environment_temperature',
      'state',
      'state_reason',
      'serial_number',
      'timestamp'
    ];

    // Sort the BMU's currently applied values into the order specified by the AIF params arrays.
    var sorted_readings = {};
    for (var i = 0; i < params_arr.length; i++) {
      if (Object.keys(readings).includes(params_arr[i])) {
        if (readings[params_arr[i]] != undefined) {
          sorted_readings[params_arr[i]] = readings[params_arr[i]];
        } else {
          sorted_readings[params_arr[i]] = 'Unknown';
        }
      }
    }

    return sorted_readings;
  };

  return (
    <div className='reading-selector-container'>
      {selectedReadingName === 'current' ? ( // We want to display current and voltage next to each other on the same line.
        <div className='selected-bmu-reading'>
          <div
            className={`selected-bmu-reading-value selected-bmu-reading-current-${column_name} ${onlineStatus} ${
              isReadingZero(readings.current) ? 'grayText' : ''
            }`}
          >
            {' '}
            {/* Add ${blinkCurrent} to className if we get the blink reading working. */}
            {displayValue(readings.current, 'current') + readingUnit.current}
          </div>
          <div
            className={`selected-bmu-reading-value selected-bmu-reading-voltage-${column_name} ${onlineStatus} ${
              isReadingZero(readings.voltage) ? 'grayText' : ''
            }`}
          >
            {' '}
            {/* Add ${blinkVoltage} to className if we get the blink reading working. */}
            {displayValue(readings.voltage, 'voltage') + readingUnit.voltage}
          </div>
        </div>
      ) : (
        <div className='selected-bmu-reading'>
          <div
            className={`selected-bmu-reading-value ${onlineStatus} ${
              isReadingZero(readings[selectedReadingName]) ? 'grayText' : ''
            }`}
          >
            {' '}
            {/* Add ${blinkReading} to className if we get the blink reading working. */}
            {displayValue(readings[selectedReadingName], selectedReadingName) +
              readingUnit[selectedReadingName]}
          </div>
        </div>
      )}

      <select
        ref={readingsSelectField}
        className={`reading-selector reading-selector-${column_name}`}
        onChange={handleReadingsSelectChange}
        onClick={handleClick}
        title={
          selectedReadingName === 'current'
            ? 'current and voltage'
            : selectedReadingName
        }
      >
        {Object.keys(sortReadingsForDisplay(readings)).map((reading, index) => {
          // Display an option in the dropdown for each BMU reading. Amps and Voltage will display on the same line together.
          if (
            reading !== 'voltage' &&
            reading !== 'cell_voltages' &&
            reading !== 'cell_temperatures'
          ) {
            return (
              <option
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                value={readings[reading]}
                title={reading === 'current' ? 'current and voltage' : reading}
                readingname={reading}
                className={`${onlineStatus} ${
                  isReadingZero(readings[reading]) ? 'grayText' : ''
                }`}
              >
                {displayValue(readings[reading], reading) +
                  readingUnit[reading]}{' '}
                &emsp;
                {
                  reading === 'current' // We want to display the voltage along side the current on the same line.
                    ? displayValue(readings.voltage, 'voltage') +
                      readingUnit.voltage
                    : '' // Don't display anything extra if the reading is not the current reading.
                }
              </option>
            );
          }
        })}
      </select>
    </div>
  );
};

BmuReadingsSelector.propTypes = {
  readings: PropTypes.object,
  onlineStatus: PropTypes.string,
  selectedReadingName: PropTypes.string,
  setSelectedReadingName: PropTypes.func,
  selectedReadingIndex: PropTypes.number,
  setSelectedReadingIndex: PropTypes.func,
  column_name: PropTypes.string
};
