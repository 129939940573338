/* eslint-disable @shopify/jsx-no-complex-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { renderReading } from '../readings.js';
import { isOnline } from '../helpers/isOnline.js';
import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';

export function MiniTransformer({
  ulid,
  name,
  xaxis,
  yaxis,
  titlePosition,
  overrideName,
  timezone,
  isTabletOrMobile
}) {
  const [readings, setReadings] = useState({});
  const [transformerName, setTransformerName] = useState(name);
  const [meterReading, setMeterReading] = useState('');
  const [selectedReading, setSelectedReading] = useState(() => {
    if (localStorage.getItem('selectedReading'.concat(ulid)) != null) {
      return localStorage.getItem('selectedReading'.concat(ulid));
    }
    return 'kw_reading';
  });
  const [online, setOnline] = useState(false);

  useEffect(() => {
    subscribe(meterReadingsEventName, (e) =>
      setReadings(e.detail?.[ulid] || {})
    );
    return () => unsubscribe(meterReadingsEventName);
  }, [ulid]);

  useEffect(() => {
    localStorage.setItem('selectedReading'.concat(ulid), selectedReading);
    let _online = false;
    if (Object.keys(readings).length !== 0) {
      _online =
        'read_at' in readings
          ? isOnline(parseInt(readings.read_at) * 1000)
          : true;
      if ('name' in readings) setTransformerName(readings.name);
      if (selectedReading in readings) {
        setMeterReading(
          renderReading(
            selectedReading,
            setSelectedReading,
            readings,
            _online,
            timezone,
            isTabletOrMobile
          )
        );
      }
    }
    setOnline(true);
  }, [readings, selectedReading]);

  function offlineClass(className) {
    return !online ? className : '';
  }

  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  return (
    <div
      className='apparent-module mini-transformer'
      id={ulid}
      style={customStyle}
    >
      <div className='mini-transformer-container'>
        {(titlePosition === undefined || titlePosition === 'top') && (
          <div className={`mini-transformer-name ${offlineClass('offline')}`}>
            {transformerName}
          </div>
        )}
        {titlePosition === 'left' && (
          <div
            className={`meter-name ${offlineClass('offline')}`}
            style={{
              position: 'fixed',
              marginLeft: '-50px',
              marginTop: '20px'
            }}
          >
            {transformerName}
          </div>
        )}
        {titlePosition === 'right' && (
          <div
            className={`meter-name ${offlineClass('offline')}`}
            style={{
              position: 'fixed',
              marginLeft: '135px',
              marginTop: '20px'
            }}
          >
            {transformerName}
          </div>
        )}
        <div className={`transformer-block ${offlineClass('offline-border')}`}>
          <div
            className={`mini-transformer-readings ${offlineClass('offline')}`}
          >
            {meterReading}
            {overrideName ? overrideName : ''}
          </div>
        </div>
      </div>
    </div>
  );
}

MiniTransformer.propTypes = {
  ulid: PropTypes.number,
  name: PropTypes.string,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  timezone: PropTypes.string,
  isTabletOrMobile: PropTypes.bool,
  titlePosition: PropTypes.string,
  overrideName: PropTypes.string
};
